<template>
  <div class="pa-3">
    <input
      type="file"
      id="files"
      ref="files"
      class="excel-upload-input"
      :multiple="editedItem.id < 0"
      v-on:change="select_file"
      :accept="accept"
      v-if="showDetail"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <span>
              <v-checkbox
                v-model="editedItem.toshare"
                label="a partager "
                :readonly="readonly"
                dense
              ></v-checkbox>
            </span>
            <v-spacer></v-spacer>
            <span v-if="editedItem.id < 0">
              <v-checkbox
                v-model="editedItem.isurl"
                label="Url "
                :readonly="readonly"
                dense
              ></v-checkbox>
            </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.ref"
                    :readonly="readonly"
                    :disabled="isRevision"
                    label="Reference"
                    required
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.title"
                    :readonly="readonly"
                    label="Titre"
                    :disabled="isRevision"
                    :rules="[(v) => !!v || 'Titre obligatoire']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="!editedItem.isurl && (editedItem.id < 0 || isRevision)"
                >
                  <v-btn
                    color="blue darken-1"
                    @click="addFiles()"
                    v-if="!readonly"
                  >
                    <v-icon> mdi-paperclip </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="editedItem.isurl">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.filename"
                    :readonly="readonly"
                    :disabled="isRevision"
                    label="Url"
                    @input="validateUrl"
                    :rules="[
                      (v) => !!v || 'Url obligatoire',
                      (v) => !v || isValid || 'Url invalide',
                    ]"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="9" md="9">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.description"
                    :readonly="readonly"
                    :disabled="isRevision"
                    label="Description"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <datepicker
                    label="Date"
                    v-model="editedItem.doc_date"
                    :edit="true"
                    :clearable="false"
                    :date_max="$store.state.today"
                    :rules="[(v) => !!v || 'Date obligatoire']"
                    :key="cs"
                    v-if="editedItem.id < 0"
                  ></datepicker>
                  <datepicker
                    label="Date"
                    v-model="editedItem.newdate"
                    :edit="true"
                    :clearable="false"
                    :date_min="editedItem.doc_date"
                    :date_max="$store.state.today"
                    :rules="[(v) => !!v || 'Date obligatoire']"
                    :key="cs"
                    v-if="isRevision"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="1" md="1" v-if="doc_type == -1">
                  <v-text-field
                    type="number"
                    autocomplete="off"
                    dense
                    v-model.number="editedItem.version"
                    :readonly="readonly"
                    :disabled="isRevision"
                    :rules="[(v) => v != null || 'Version obligatoire']"
                    label="Version"
                    @focus="$event.target.select()"
                    v-if="editedItem.id < 0 && editedItem.isurl == 0"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="editedItem.id < 0 && !editedItem.isurl"
                >
                  <v-chip
                    v-for="file in chosenFiles"
                    :key="file.name"
                    close
                    @click:close="delchips(file)"
                  >
                    {{ file.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <span v-if="sendingfile"> sending : {{ sendingfile }} </span>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click="upload()"
              v-if="
                !readonly &&
                (editedItem.id < 0 || isRevision) &&
                !editedItem.isurl
              "
              :disabled="chosenFiles.length == 0"
            >
              Enregistrer
            </v-btn>

            <v-btn
              color="blue darken-1"
              @click="save()"
              v-if="
                !readonly &&
                !isRevision &&
                (editedItem.id > 0 || editedItem.isurl)
              "
              :disabled="change == 1"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import UPLOAD from "../graphql/UPLOAD.gql";
import UPSERT_DOC from "../graphql/File/UPSERT_DOC.gql";
import TIERS_CURSOR from "../graphql/Tier/TIERS_CURSOR.gql";
import JSZip from "jszip";
//import axios from "axios";
export default {
  components: {
    datepicker: () => import("./DatePicker.vue"),
  },
  name: "fileform",
  props: {
    item: Object,
    readonly: Boolean,
    showForm: Boolean,
    items: Array,
    doc_type: Number,
    extensions: Array,
    isRevision: { type: Boolean, default: false },
    categories: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    chosenFiles: [],
    filesize: 0,
    kf: 100,
    klist: 200,
    cs: 300,
    st: 400,
    st2: 500,
    url: {},
    accept: "",
    isValid: false,
    sendingfile: null,
    cat_change: 0,
    change: 0,
  }),

  computed: {
    variableadd() {
      let w = {
        statut_id: [2, 3],
        Kind: 0,
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },

    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouveau Document "
          : (this.isRevision ? "Revision du " : "") +
              "Document : ".concat(this.item.title);
      else return "";
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.change++;
      },
      deep: true,
    },
    "editedItem.categories": {
      handler() {
        this.cat_change++;
      },
      deep: true,
    },
  },

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.cs++;
      this.st++;
      this.st2++;
    }
    if (this.item.id < 0) {
      this.editedItem.doc_date = this.$store.state.today;
      this.editedItem.toshare = 1;
      this.editedItem.version = 0;
    }
    if (this.extensions)
      this.extensions.forEach((element) => {
        if (this.accept == "") this.accept = "." + element.ext;
        else this.accept = this.accept + ",." + element.ext;
      });
  },

  methods: {
    tierChange(item) {
      this.editedItem.tier_id = item;
    },
    validateUrl() {
      const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      this.isValid = pattern.test(this.editedItem.filename);
    },
    async up_file(content, cat) {
      this.editedItem.doc_type = this.item.doc_type;
      this.editedItem.c_size = content.size / 1000;
      this.editedItem.create_uid = this.$store.state.me.id;
      await this.$apollo
        .mutate({
          mutation: UPLOAD,

          variables: {
            file: content,
            title: this.editedItem.title,
            description: this.editedItem.description,
            ref: this.editedItem.ref,
            filename: this.editedItem.filename,
            doc_date: this.isRevision
              ? this.editedItem.newdate
              : this.editedItem.doc_date,
            size: this.editedItem.size,
            c_size: this.editedItem.c_size,
            doc_id: this.editedItem.doc_id,
            doc_type: this.item.doc_type,
            tier_id: this.editedItem.tier_id,
            position: this.editedItem.position,
            toshare: this.editedItem.toshare ? 1 : 0,
            auth: this.editedItem.auth,
            parent_id: this.isRevision ? this.item.id : null,
            create_uid: this.$store.state.me.id,
            categories: cat,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          let d = JSON.parse(data.data.Upload);
          this.editedItem = d;
          if (this.items && this.isRevision) this.items.push(this.editedItem);
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
      // const formData = new FormData();
      // formData.append("file", content);
      // await axios
      //   .post("http://localhost/upload.php", formData)
      //   .then((response) => {
      //     const hashedFileName = response.data.file;

      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     this.snackbar_color = "error";
      //     this.snackbar_text = error.message;
      //     this.snackbar = true;
      //     // Handle file upload errors
      //   });
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      this.validateUrl();
      if (this.$refs.form.validate()) {
        let cat;
        if (this.cat_change > 0) cat = this.editedItem.categories;
        let v = {
          input: [
            {
              id: this.editedItem.id > 0 ? this.editedItem.id : null,
              title: this.editedItem.title,
              description: this.editedItem.description,
              filename: this.editedItem.filename,
              doc_date: this.editedItem.doc_date,
              version: this.editedItem.version,
              isurl: this.editedItem.isurl ? 1 : 0,
              ref: this.editedItem.ref,
              doc_id: this.item.doc_id,
              doc_type: this.item.doc_type,
              tier_id: this.editedItem.tier_id,
              position: this.editedItem.position,
              auth: this.item.auth,
              toshare: this.editedItem.toshare ? 1 : 0,
              categories: cat,
              write_uid: this.$store.state.me.id,
            },
          ],
        };
        let r = await this.maj(UPSERT_DOC, v, true);
        if (r) {
          this.cat_change = 0;
          this.change = 1;
          this.$emit("changed");
          this.close();
        }
      }
    },

    async upload() {
      if (this.$refs.form.validate()) {
        let cat;
        if (this.cat_change > 0) cat = this.editedItem.categories;
        if (this.chosenFiles) {
          for (let index = 0; index < this.chosenFiles.length; index++) {
            const element = this.chosenFiles[index];
            if (!this.progress && element.size > 0) {
              this.editedItem.filename = element.name;
              this.sendingfile = element.name;
              this.editedItem.size = element.size / 1000;
              this.progress = true;
              var zip = new JSZip();
              zip.file(element.name, element);
              let content = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: { level: 9 },
              });
              await this.up_file(content, cat);
              this.sendingfile = null;
              this.progress = false;
            }
          }
          this.$nextTick(() => {
            this.change = 1;
            this.$emit("added", this.chosenFiles.length);
            this.close();
          });
        }
      }
    },

    select_file() {
      if (this.$refs.files.files) {
        let ok = true;
        for (let index = 0; index < this.$refs.files.files.length; index++) {
          const element = this.$refs.files.files[index];
          if (
            element.size >
            this.$store.state.options[0].max_file_size * 1000000
          ) {
            this.snackbar_text =
              "Impossible d'ajouter le fichier " +
              element.name +
              " ! Taille fichier supperieure à " +
              this.$store.state.options[0].max_file_size +
              " Mb";
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (ok) {
          this.chosenFiles = this.$refs.files.files;
          if (!this.editedItem.title)
            this.editedItem.title = this.$refs.files.files[0].name
              .split(".")
              .slice(0, -1)
              .join(".");
          this.kf++;
        }
      }
    },
    delchips(item) {
      let i = this.chosenFiles.findIndex((elm) => elm.name == item.name);
      if (i >= 0) {
        this.chosenFiles.splice(i, 1);
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
